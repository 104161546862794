import { Component, OnInit } from '@angular/core';
import {CardsService} from '../shared/cards.service';
import {Router} from '@angular/router';
import {AngularFireDatabase,AngularFireList} from 'angularfire2/database';




@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  gameid:any;
  val:any;


  constructor(private cardsService: CardsService,private af:AngularFireDatabase) { }
    cardsArray = [];

 
    ngOnInit() {
  
     
  }


  // Create the game if doesn't exist or join the game if it exists
  Onsubmit(){
    let gameId ={
      gameid:this.gameid
    }
    this.cardsService.getId(this.gameid).subscribe(
      list => {

        // if the game project doesn't exist -- create one
        if(Object(list.length)==0){

          this.cardsService.addgame(gameId);
          this.cardsService.Gameid(gameId);
        }


        // if game project exists -- display the game project ID 
        else {
          console.log(Object(list[0]).key);
          this.cardsService.Gameid(Object(list[0]).key);
        
        }

  });
}

}
